<template>
  <div class="fili">
    <div class="title">食安溯源</div>
    <div class="fun">
      <div class="search">
        <a-form layout="inline">
        <a-form-item label="日期">
            <a-date-picker v-model="selectedDate" size="large" />
        </a-form-item>
        <a-form-item label="食材/原材料">
            <a-input size="large" placeholder="请输入菜品名称" v-model="keyword" />
        </a-form-item>
        <a-form-item>
            <a-button type="primary" size="large" @click="loadData">
            搜索
            </a-button>
            <a-button size="large" @click="exportData" :loading="isDownloading">导出</a-button>
            <a-upload
            name="file"
              :withCredentials="true"
              :showUploadList="false"
              accept=".xlsx"
              :action="uploadUrl"
              :beforeUpload="onBeforeUpload"
              @change="handleChange"
              :data="{school_id:currentSchool._id}"
            >
            <a-button size="large">导入</a-button>
            </a-upload>
        </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="list">
      <div class="table">
        <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        bordered
        class="custom-table"
        :scroll="{ x: 900 }"
        rowKey="rowKey"
        >
        <template slot="count" slot-scope="text,record">
           <span >{{record.count}}{{record.unit}}</span>
        </template>
        <template slot="operation" slot-scope="text,record">
            <a @click="clickDetail(record)">详情</a>
        </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import apis from '@/business/apis'
import downloadjs from 'downloadjs'
const columns = [
  {
    title: '食材/原材料',
    dataIndex: 'name',
    width: 160
  },
  {
    title: '时间',
    dataIndex: 'time',
    width: 180
  },
  {
    title: '重量/数量',
    dataIndex: 'count',
    scopedSlots: { customRender: 'count' },
    width: 160
  },
  {
    title: '供应商',
    dataIndex: 'origin'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
    width: 80
  }
]

export default {
  inject: ['eventBus'],
  data () {
    return {
      selectedDate: null,
      keyword: '',
      data: [],
      columns,
      isDownloading: false,
      uploadUrl: apis.supply.importExcel,
      pagination: {
        current: 1
      }
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    async loadData () {
      let query = '?schoolId=' + this.currentSchool._id + '&current=' + this.pagination.current
      if (this.selectedDate) {
        query += '&date=' + this.selectedDate.format('YYYY-MM-DD')
      }
      if (this.keyword) {
        query += '&keyword=' + this.keyword
      }
      const response = await this.$http.get(apis.supply.list + query)
      if (response.body.err) return
      this.data = response.body.list
      this.data.forEach((item, index) => {
        item.time = moment(item.finished_at).format('YYYY-MM-DD HH:mm')
        item.rowKey = index
      })
      this.pagination = response.body.pagination
    },
    tableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.loadData()
    },
    clickDetail (record) {
      this.eventBus.$emit('show-tracedetail-modal', record)
    },
    handleChange ({ file }) {
      switch (file.status) {
        case 'uploading':
          this.isUploading = true
          break
        case 'done':
          this.isUploading = false
          this.handleUploadFinish(file.response)
          break
        case 'error':
          this.isUploading = false
          this.$message.error('上传失败')
          break
      }
    },
    async handleUploadFinish (result) {
      if (result.err) {
        this.$message.error(result.errMsg)
        return
      }
      // this.$message.success('导入完成')
      this.$message.success(`导入完成，新增数据${result.nInserted}条，修改${result.nModified}条，失败${result.nFailed}条`)
      this.loadData()
    },
    async onBeforeUpload (file) {
      const maxSize = 25 * 1000 * 1000
      if (file.size > maxSize) {
        this.$message.error('文件大小不可以超过25M')
        return Promise.reject(Error())
      }
    },
    async exportData () {
      this.$message.info('正在导出...')
      this.isDownloading = true
      setTimeout(() => {
        this.isDownloading = false
      }, 1000)
      const data = { school_id: this.currentSchool._id }
      if (this.selectedDate) {
        data.date = this.selectedDate.format('YYYY-MM-DD')
      }
      if (this.keyword) {
        data.keyword = this.keyword
      }
      const response = await this.$http.post(apis.supply.exportExcel, data, { responseType: 'blob' })
      if (!response || !response.ok) {
        this.$message.info('导出失败')
        return
      }
      downloadjs(
        await response.blob(),
        `食材溯源_${moment().format('YYYYMMDDTHHmmss')}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    }
  },
  created () {
    this.loadData()
  }
}
</script>

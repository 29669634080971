<template>
  <a-modal
      :visible="showModal"
      @ok="closeModal"
      @cancel="closeModal"
      :footer="null"
      class="customermodel"
      width="600px"
      :centered="true"
    >
      <div class="descinfo-model">
        <div class="hd">
          <h1>{{data.name}}</h1>
          <p>批号：{{data.chain_num||'--'}}</p>
        </div>
        <div class="info">
          <a-row>
            <a-col :span="8">
              <div class="tiva">
                <div class="title">日期/时间</div>
                <div class="value">{{data.time||'--'}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="tiva">
                <div class="title">重量/数量</div>
                <div class="value">{{data.count||'--'}}{{data.unit}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="tiva">
                <div class="title">最后操作人</div>
                <div class="value">{{data.last_operator||'--'}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="tiva">
                <div class="title">配送人</div>
                <div class="value">{{data.related_operator||'--'}}</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="tiva">
                <div class="title">领料人</div>
                <div class="value">{{data.operator||'--'}}</div>
              </div>
            </a-col>
            <a-col :span="8" v-if="false">
              <div class="tiva">
                <div class="title">厨师</div>
                <div class="value">--</div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="timeline">
          <a-timeline>
            <a-timeline-item v-for="(item,index) in chains" :key="index">
              <a-icon
                v-if="index + 1 === chains.length"
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px;"
              />
              <div class="item">
                <div class="name">{{item.target}}</div>
                <div class="other">
                  <div class="addres">地址：{{item.target_address}}</div>
                  <div class="time">时间：{{item.finished_at}}</div>
                  <div class="ordernum">单号：{{item.form_num}}</div>
                </div>
              </div>
            </a-timeline-item>
            <!-- <a-timeline-item>
              <a-icon
                slot="dot"
                type="clock-circle-o"
                style="font-size: 16px;"
              />
              <div class="item">
                <div class="name">福建辉哥农场</div>
                <div class="other">
                  <div class="addres">地址：福建省厦门市彩虹区北京路292号</div>
                  <div class="ordernum">单号：124214</div>
                </div>
              </div>
            </a-timeline-item> -->
          </a-timeline>
        </div>
        <div class="button">
          <a-button type="primary" size="large" @click="closeModal">
            确认
          </a-button>
        </div>
      </div>
  </a-modal>
</template>
<script>
import moment from 'moment'
export default {
  inject: ['eventBus'],
  data () {
    return {
      data: {},
      chains: [],
      showModal: false
    }
  },
  methods: {
    showDetailModal (data) {
      this.showModal = true
      this.data = data
      this.chains = []
      this.chains.push({
        form_num: data.form_num,
        target: data.target,
        target_address: data.target_address,
        finished_at: moment(data.finished_at).format('MM-DD HH:mm')
      })
      data.chains.forEach(item => {
        item.finished_at = moment(item.finished_at).format('MM-DD HH:mm')
        this.chains.push(item)
      })
    },
    closeModal () {
      this.showModal = false
    }
  },
  async created () {
    this.eventBus.$on('show-tracedetail-modal', this.showDetailModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-tracedetail-modal', this.showDetailModal)
  }
}
</script>

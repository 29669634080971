<template>
  <div class="food-safety-traced">
    <traced-list></traced-list>
    <traced-detail></traced-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tracedList from '@/components/foodSafetyTraced/list'
import tracedDetail from '@/components/foodSafetyTraced/detail'
export default {
  components: {
    tracedList,
    tracedDetail
  },
  computed: {
    ...mapGetters('user', [
      'isTeacher'
    ])
  },
  created () {
    if (this.isTeacher) {
      this.$router.back()
    }
  }
}
</script>

<style lang="less">
.food-safety-traced {
  .total {
    margin: 0 -12px;
    .item {
      border: 1px solid #e7e7eb;
      background: linear-gradient(180deg, #ffffff, #fbfbfb);
      border-radius: 6px;
      margin: 0 12px;
      line-height: 1;
      .bd {
        padding: 16px;
        display: flex;
        .icon {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          img {
            width: 100%;
          }
        }
        .tenu {
          .num {
            margin-top: 8px;
            margin-bottom: -3px;
            b {
              font-size: 30px;
            }
            span {
              font-size: 12px;
              font-weight: normal;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  .fili {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      font-size: 20px;
      padding: 16px 0 12px;
    }
    .fun {
      .search {
        .ant-input {
          height: 44px;
        }
        .ant-btn {
          width: 80px;
          margin-right: 16px;
        }
      }
    }
    .list {
      .table {
        margin: 16px 0;
      }
    }
  }
}
.descinfo-model {
  .hd {
    h1 {
      font-size: 28px;
      margin: 0;
    }
    p{
      margin:0;
      font-size: 14px;
      color: #999;
    }
  }
  .info {
    margin: 16px -12px 0 0;
    .tiva {
      padding: 14px 12px 12px;
      margin: 0 12px 12px 0;
      line-height: 1.2;
      background: #f2f2f2;
      border-radius: 6px;
      height: 70px;
      .title {
        color: #999;
      }
      .value {
        margin-top: 8px;
        font-size: 16px;
      }
    }
  }
  .timeline {
    margin: 24px 0 -20px;
    .item {
      .name {
        font-size: 16px;
      }
      .other {
        color: #999;
        margin-top: 4px;
        > div {
          margin-top: 2px;
        }
      }
    }
  }
  .button {
    border-top: 1px solid #e8e8e8;
    text-align: center;
    padding-top: 24px;
    margin-top: 20px;
    .ant-btn {
      width: 120px;
    }
  }
}
</style>
